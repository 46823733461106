import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div className="flex justify-between items-center py-4 px-8 bg-white shadow-md">
            {/* Logo and Title */}
            <div className="flex items-center">
                {/* Circular Logo Placeholder */}
                <div className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center">
                    <div className="w-4 h-4 rounded-full bg-green-500"></div>
                </div>
                {/* Title */}
                <h1 className="ml-4 text-lg font-semibold text-green-600">
                    Vehicle Diagnostic ChatBot
                </h1>
            </div>

            {/* Navigation Links */}
            <div className="flex space-x-8">
                <a href="https://corporate.smartmaintenancesolutions.com/home"
                   className="text-gray-600 hover:text-green-600">
                    Corporate Solutions
                </a>
                <a href="https://launch.smartmaintenancesolutions.com/individual-services763817"
                   className="text-gray-600 hover:text-green-600">
                    Individual Services
                </a>
                <a href="/" className="text-gray-600 hover:text-green-600">
                    AI Powered Diagnostic
                </a>

            </div>

        </div>
    );
};

export default Header;
