import React, {useEffect, useRef, useState} from "react";
import styles from "../scss/main.module.scss"
import {AnimatePresence, motion} from "framer-motion"
import {
    title_animation,
    spacer_animation,
    menu_bar_animation,
    navigation_window_animation,
    navigation_window_container_animation
} from "../Animation/MainAnimation";
import Modal from "react-modal"
import {Link, Outlet, useLocation} from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
// sudo scp -i ~/Downloads/TestServerKey.pem -r ./build/* ec2-user@54.215.250.120:/home/ec2-user/frontend/

Modal.setAppElement('#root');
const Main = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [IsNavigationHover, setIsNavigationHover] = useState<boolean>(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            IsNavigationHover &&
            !(event.target as HTMLElement).closest(`.${styles.navigation_window}`) &&
            !(event.target as HTMLElement).closest(`.${styles.menu_container}`)
        ) {
            setIsNavigationHover(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [IsNavigationHover]);
    return (
        <div className="w-full min-h-screen overflow-x-hidden">
            {/* Top border */}
            <div className={styles.top_border}></div>

            {/* Container with responsive settings */}
            <div className="w-full p-4 border-4 min-h-screen flex flex-col">
                {/* Header */}
                <div className="w-full flex-shrink-0">
                    <Header/>
                </div>

                {/* Main Content Center */}
                <div className="flex-grow w-full mt-4">
                    <div className="w-full">
                        <Outlet/>
                    </div>
                </div>

                {/* Footer */}
                <div className="w-full mt-4 flex-shrink-0">
                    <Footer/>
                </div>
            </div>
        </div>

    )
}

export default Main;


// <div className={styles.main_right}>
//     {location.pathname === '/diagnostic' && (
//         <div className={styles.checklist_container}>
//             <div className={styles.checklist_item}>
//                 <p>Question 1</p>
//                 <motion.div className={styles.spacer}
//                             initial={{width: 0}}
//                             animate={{width: currentStep == 1 ? '100%' : '0%'}}
//                             transition={{duration: 0.5, ease: 'easeOut'}}></motion.div>
//             </div>
//             <div className={styles.checklist_item}>
//                 <p>Question 2</p>
//                 <motion.div className={styles.spacer}
//                             initial={{width: 0}}
//                             animate={{width: currentStep == 2 ? '100%' : '0%'}}
//                             transition={{duration: 0.5, ease: 'easeOut'}}></motion.div>
//             </div>
//             <div className={styles.checklist_item}>
//                 <p>Question 3</p>
//                 <motion.div className={styles.spacer}
//                             initial={{width: 0}}
//                             animate={{width: currentStep == 3 ? '100%' : '0%'}}
//                             transition={{duration: 0.5, ease: 'easeOut'}}></motion.div>
//             </div>
//             <div className={styles.checklist_item}>
//                 <p>Issue Summary</p>
//                 <motion.div className={styles.spacer}
//                             initial={{width: 0}}
//                             animate={{width: currentStep == 4 ? '100%' : '0%'}}
//                             transition={{duration: 0.5, ease: 'easeOut'}}></motion.div>
//             </div>
//         </div>
//     )}
// </div>

// {location.pathname === '/' && (
//     <div className={style3D.container}>
//         <div className={style3D.audiObj_container}>
//             <div ref={mountRef} className={style3D.audiObj}>
//
//             </div>
//             <div ref={textContainerRef} className={style3D.text_container}>
//                 <p>Introduce Our Compony this is most innovative period in the world for you and me
//                     and
//                     us That's why we need to use this theory. You are a Vehicle Diagnostic, Repair,
//                     and
//                     Maintenance Assistant.
//
//                     Your job is to:
//                     • Gather and process information provided by vehicle owners regarding vehicle
//                     issues
//                     and maintenance history.
//                     • Use a comprehensive understanding of automotive diagnostics, maintenance, and
//                     repair to assess the information received.
//                     • Provide accurate and knowledgeable advice and recommendations for diagnostics,
//                     repair, and maintenance based on the information collected.
//                     • Limit follow-up questions to a minimum and provide concise, actionable
//                     solutions
//                     as quickly as possible.
//
//                     You have the following Qualifications:
//                     • ASE (Automotive Service Excellence) certification or equivalent qualification.
//                     • Extensive experience in automotive diagnostics, maintenance, and repairs.
//                     • Proficiency in customer service and communication skills.
//                     • Ability to demonstrate a thorough understanding of vehicle systems and
//                     potential
//                     issues.
//
//                     Level of Authority:
//                     • Authorized to provide initial diagnostic and maintenance advice to customers.
//                     • Required to escalate complex or unusual cases to senior technicians or
//                     management
//                     as needed.
//
//                     You have the following Training and Guidelines:
//                     • Use standardized and best practices in diagnostic and maintenance guidelines.
//                     • Participate in ongoing training and professional development to stay updated
//                     with
//                     industry best practices and technological advancements.
//
//                     As it relates with compliance:
//                     • You maintain compliance with all relevant laws, regulations, and company
//                     policies
//                     related to customer service, automotive diagnostics, and repair.
//
//                     You are understanding, kind, and concise.</p>
//             </div>
//
//         </div>
//         <div className={style3D.main_picture_img_container}
//              style={{opacity: imageOpacity, transition: 'opacity 0.5s ease-in-out'}}>
//             <img src="/static/Pictures/MainPagePicture.jpg" alt="MainPagePicture"/>
//             <div className={style3D.sub_img_container}>
//                 <img src="/static/Pictures/MainPagePicture2.jpg" alt="MainPagePicture2"/>
//                 <img src="/static/Pictures/MainPagePicture3.jpeg" alt="MainPagePicture3"/>
//             </div>
//         </div>
//         <div className={style3D.subtext_container}
//              style={{opacity: imageOpacity, transition: 'opacity 0.5s ease-in-out'}}>
//             <p>I'll Be There For You</p>
//         </div>
//     </div>
// )}

// <div className={styles.header_left}>
//     <motion.div className={styles.menu_container} initial={menu_bar_animation.initial}
//                 whileHover={menu_bar_animation.animate}
//                 transition={menu_bar_animation.transition}
//                 onHoverStart={() => !isMobile && setIsNavigationHover(true)}
//                 onClick={() => {
//                     isMobile && setIsNavigationHover(true)
//                 }}>
//         <motion.div className={styles.menu_bar} initial={menu_bar_animation.initial}
//                     whileHover={menu_bar_animation.animate}
//                     transition={menu_bar_animation.transition}></motion.div>
//         <motion.div className={styles.menu_bar} initial={menu_bar_animation.initial}
//                     whileHover={menu_bar_animation.animate}
//                     transition={menu_bar_animation.transition}></motion.div>
//         <motion.div className={styles.menu_bar} initial={menu_bar_animation.initial}
//                     whileHover={menu_bar_animation.animate}
//                     transition={menu_bar_animation.transition}></motion.div>
//     </motion.div>
// </div>
// <motion.div className={styles.header_center}>
//     <div></div>
//     <motion.div className={styles.logo_container} initial={title_animation.initial}
//                 whileInView={title_animation.whileInView}>
//         <p>Vehicle Diagnostic ChatBot</p>
//     </motion.div>
//     <motion.div className={styles.spacer} initial={spacer_animation.initial}
//                 whileInView={spacer_animation.whileInView}></motion.div>
// </motion.div>
// <motion.div className={styles.header_right}>
//     <div className={styles.virtualContainer}></div>
//     <motion.div className={styles.btnContainer} initial={title_animation.initial}
//                 whileInView={title_animation.whileInView}>
//         {/*<button className={styles.signInBtn}>Sign In</button>*/}
//         {/*<button className={styles.signUpBtn}>Sign Up</button>*/}
//     </motion.div>
//     {/*<motion.div className={styles.spacer} initial={spacer_animation.initial}*/}
//     {/*            whileInView={spacer_animation.whileInView}></motion.div>*/}
// </motion.div>